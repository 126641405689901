/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import { withFirebase } from "../../../utils/Firebase"
import CustomInput from "../../atoms/CustomInput"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"

const DepartmentDrawer = ({
  firebase,
  open,
  closeDrawer,
  orgId,
  locationId,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_location_department")}
      internal
    >
      <div>
        <Formik
          initialValues={{
            departmentName: undefined,
          }}
          onSubmit={async (values, { resetForm }) => {
            setIsCreating(true)
            try {
              await firebase.addOrgLocationDepartment({
                orgId,
                locationId,
                departmentData: {
                  displayName: values.departmentName,
                  active: true,
                },
              })

              addToast(t("saved_successfully"), {
                appearance: "success",
              })
              resetForm({ value: "" })
              setIsCreating(false)
              closeDrawer()
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            departmentName: Yup.string().required(
              t("error_all_fields_required")
            ),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <p>
                Ingrese los datos del Departamento que desea crear dentro de la
                Sede.
              </p>

              <CustomInput
                touched={props.touched.departmentName}
                errors={props.errors.departmentName}
                icon="user"
                value={props.values.departmentName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("create_location_department")}
                type="text"
                id="departmentName"
                name="departmentName"
              />

              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

DepartmentDrawer.defaultProps = {
  open: false,
}

DepartmentDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
}
export default withFirebase(DepartmentDrawer)
